export const HTML_CONFIG = {
	theme: 'vs-dark',
	language: 'html',
	minimap: { enabled: false },
	readOnly: true,
	automaticLayout: true,
	scrollbar: {
		handleMouseWheel: false,
	},
};
export const TS_CONFIG = {
	theme: 'vs-dark',
	language: 'typescript',
	minimap: { enabled: false },
	readOnly: true,
	automaticLayout: true,
	scrollbar: {
		handleMouseWheel: false,
	},
};

export const HTML_PLACEHOLDER = '<!-- This is an autogenerated code -->';
export const TS_PLACEHOLDER = '// This is an autogenerated code';

export const LINE_HEIGHT = 19;
