import { BehaviorSubject, Observable } from 'rxjs';

import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root',
})
export class GeneratedCodeService {
	public get loadSuccess$(): Observable<boolean> {
		return this._loadSuccess$.asObservable();
	}

	private _loadSuccess$ = new BehaviorSubject<boolean>(false);

	public emitLoadSuccess(): void {
		this._loadSuccess$.next(true);
	}
}
