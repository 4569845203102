import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CoreModule } from '@core/core.module';

import { GeneratedCodeComponent } from './components/generated-code/generated-code.component';
import { SectionComponent } from './components/section/section.component';
import { MaterialModule } from './material.module';
import { BeautifyPathPipe } from './pipes/beautify-path.pipe';

@NgModule({
	declarations: [GeneratedCodeComponent, SectionComponent, BeautifyPathPipe],
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		CoreModule,
		MaterialModule,
	],
	exports: [
		GeneratedCodeComponent,
		BeautifyPathPipe,
		FormsModule,
		ReactiveFormsModule,
		SectionComponent,
		CoreModule,
	],
})
export class SharedModule {}
