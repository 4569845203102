<header class="pp-section__header">
	<h2 class="pp-section__header__title" *ngIf="!isChild">{{ title }}</h2>
	<mat-icon class="pp-section__header__arrow" (click)="toggle()">
		{{ arrowIcon }}
	</mat-icon>
	<h4 class="pp-section__header__title" *ngIf="isChild">{{ title }}</h4>
	<hr class="pp-section__header__hr" />
</header>

<main class="pp-section__main" [class]="contentWrapperClass">
	<ng-content></ng-content>
</main>
