/* eslint-disable @typescript-eslint/member-ordering */
import * as prettierPluginEstree from 'prettier/plugins/estree';
import htmlPlugin from 'prettier/plugins/html';
import typescriptPlugin from 'prettier/plugins/typescript';
import { format } from 'prettier/standalone';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import {
	AfterViewInit,
	Component,
	DestroyRef,
	ElementRef,
	inject,
	Input,
	OnChanges,
	SimpleChanges,
	ViewChild,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { PlcUnsubscribable } from '@core/interfaces/subscribable.interface';

import {
	HTML_CONFIG,
	HTML_PLACEHOLDER,
	LINE_HEIGHT,
	TS_CONFIG,
	TS_PLACEHOLDER,
} from './generated-code.constants';
import { GeneratedCodeService } from './generated-code.service';

const HTML_PRINT_WIDHT = 40;
const TS_PRINT_WIDHT = 80;
const PADDING = 10;
const COPY_TICK_INTERVAL = 1000;
@Component({
	selector: 'pp-generated-code',
	templateUrl: './generated-code.component.html',
	styleUrls: ['./generated-code.component.sass'],
	host: { class: 'pp-generated-code' },
})
export class GeneratedCodeComponent
	implements OnChanges, AfterViewInit, PlcUnsubscribable
{
	@Input({ required: true }) public set code(value: string) {
		this._code = value;
	}

	@Input() public lang: 'html' | 'typescript' = 'html';

	@ViewChild('editorContainer', { static: false })
	public editorContainer: ElementRef;

	public destroyRef: DestroyRef = inject(DestroyRef);

	public get icon(): string {
		return `assets/icons/${this._icon}.svg`;
	}

	public get height(): Observable<string> {
		return this._formattedCodeLines$.pipe(
			map((lines: number) => `${lines * LINE_HEIGHT + PADDING}px`),
		);
	}

	private get editorOptions(): any {
		return this.lang === 'html' ? HTML_CONFIG : TS_CONFIG;
	}

	private _generatedCode = async (): Promise<string> => {
		const placeholder =
			this.lang === 'html' ? HTML_PLACEHOLDER : TS_PLACEHOLDER;
		const formattedCode = await format(`${placeholder}${this._code}`, {
			parser: this.lang,
			plugins: [typescriptPlugin, htmlPlugin, prettierPluginEstree],
			useTabs: true,
			printWidth:
				this.lang === 'html' ? HTML_PRINT_WIDHT : TS_PRINT_WIDHT,
		});

		this._formattedCodeLines$.next(formattedCode.split('\n').length);

		return formattedCode;
	};

	private _icon: 'copy' | 'tick' = 'copy';
	private _code: string;
	private _editor: any;
	private _formattedCodeLines$ = new BehaviorSubject<number>(0);

	constructor(private genCodeService: GeneratedCodeService) {}

	ngAfterViewInit(): void {
		this.genCodeService.loadSuccess$
			.pipe(
				filter((isLoaded) => isLoaded),
				takeUntilDestroyed(this.destroyRef),
			)
			.subscribe(async () => {
				this._editor = (window as any).monaco.editor.create(
					this.editorContainer.nativeElement,
					{
						...this.editorOptions,
						value: await this._generatedCode(),
					},
				);
			});
	}

	async ngOnChanges(changes: SimpleChanges) {
		if (changes.code && this._editor) {
			this._code = changes.code.currentValue;
			this._editor.setValue(await this._generatedCode());
		}
	}

	public async copyToClipboard(): Promise<void> {
		navigator.clipboard.writeText(await this._generatedCode());
		this._icon = 'tick';

		const timeout = setTimeout(() => {
			this._icon = 'copy';
			clearTimeout(timeout);
		}, COPY_TICK_INTERVAL);
	}
}
