import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'beautifyPath',
})
export class BeautifyPathPipe implements PipeTransform {
	transform(path: string): string {
		const pathParts = path.split('/');

		return `${pathParts.slice(0, pathParts.length - 1).join(' > ')}`;
	}
}
