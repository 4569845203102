import { Component, Input } from '@angular/core';

// eslint-disable-next-line @typescript-eslint/no-magic-numbers
type SectionCol = 1 | 2 | 3 | 6;

@Component({
	selector: 'pp-section',
	templateUrl: './section.component.html',
	styles: [],
	host: { class: 'pp-section' },
})
export class SectionComponent {
	@Input({ required: true }) public title: string;
	@Input() public isChild = false;
	@Input() public resizable = false;

	/** @Input({ required: true }) is missing because null is OK */
	@Input() public set col(value: SectionCol) {
		this._col = value;
	}

	public get contentWrapperClass(): string {
		const classes: Array<string> = [];

		switch (this._col) {
			case 1:
				classes.push('pp-section__main--one-col');
				break;
			case 2:
				classes.push('pp-section__main--two-col');
				break;
			case 3:
				classes.push('pp-section__main--three-col');
				break;
			case 6:
				classes.push('pp-section__main--six-col');
				break;
			default:
				classes.push('pp-section__main--one-col');
				break;
		}

		if (!this._toggled) classes.push('pp-section__main--untoggled');

		if (this.resizable) classes.push('pp-section__main--resizable');

		return classes.join(' ');
	}

	public get arrowIcon(): string {
		return !this._toggled ? 'chevron_right' : 'expand_more';
	}

	private _col: SectionCol = 1;
	private _toggled = true;

	public toggle(): void {
		this._toggled = !this._toggled;
	}
}
